import { colors } from '@dentalux/ui-library-core'

import { styled, Typography } from '@mui/material'

import { LogoIcon } from '~/icons/LogoIcon'

export const Group = styled('div')`
  display: flex;
  align-items: center;
  color: ${colors.aqua[700]};
  gap: 16px;
`

export const AppName = styled(Typography)`
  font-size: 24px;
`

export const Logo = styled(LogoIcon)`
  fill: ${colors.aqua[700]};
  width: 56px;
  height: 24px;
`
