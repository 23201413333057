/* eslint-disable */
import { IconButton, Menu } from '@mui/material'
import { styled } from '@mui/material/styles'
import { medlogColors } from '~/providers/ThemeProvider'

export const TriggerButton = styled(IconButton)`
  border-radius: 10px;
  padding: 4px;

  & .MuiTouchRipple-root .MuiTouchRipple-child {
    border-radius: 8px;
  }
`

interface ActiveProps {
  active?: boolean
}

export const ContextMenu = styled(Menu)<ActiveProps>((props) => ({
  ul: {
    background: props.active ? medlogColors.blue[500] : medlogColors.green[500],

    li: {
      background: props.active ? medlogColors.blue[500] : medlogColors.green[500],
    },
  },
}))
