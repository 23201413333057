import { useTranslation } from 'react-i18next'

import { Typography } from '@mui/material'

import * as S from './WelcomeScreen.styles'

export const WelcomeScreen = () => {
  const { t } = useTranslation()

  return (
    <S.Container>
      <Typography variant={'Lato H3 Bold'}>{t('medlog.welcome.header')}</Typography>
      <Typography>{t('medlog.welcome.description')}</Typography>
    </S.Container>
  )
}
