import React from 'react'

import { Button, ButtonProps, CircularProgress } from '@mui/material'

type Props = ButtonProps & {
  loading: boolean
}

export const LoadingButton = React.forwardRef<HTMLButtonElement, Props>(({ loading, children, ...props }, ref) => {
  return (
    <Button disabled={loading} {...props} ref={ref}>
      {children}
      {loading && <CircularProgress sx={{ marginLeft: 1 }} color="inherit" size={15} thickness={6} />}
    </Button>
  )
})
