import React, { PropsWithChildren } from 'react'

import { useTranslation } from 'react-i18next'

import Head from 'next/head'

import { components } from '~/features/Auth/AwsCognitoComponents'

import { DefaultTexts } from '@aws-amplify/ui'
import { Authenticator } from '@aws-amplify/ui-react'
import { I18n } from 'aws-amplify'

import * as S from './Auth.styles'

export const AwsCognitoAuthenticator = ({ children }: PropsWithChildren) => {
  const { t } = useTranslation()

  I18n.putVocabulariesForLanguage('de', {
    [DefaultTexts.SIGN_IN_TAB]: t('medlog.auth.signIn.tab'),
    [DefaultTexts.CREATE_ACCOUNT]: t('medlog.auth.signUp.tab'),
    [DefaultTexts.SIGN_IN_BUTTON]: t('medlog.auth.signIn.tab'),
    [DefaultTexts.ENTER_EMAIL]: t('medlog.auth.form.email'),
    'Enter your email': t('medlog.auth.form.email'),
    [DefaultTexts.ENTER_PASSWORD]: t('medlog.auth.form.password'),
    [DefaultTexts.CONFIRM_PASSWORD_PLACEHOLDER]: t('medlog.auth.form.confirm-password'),
    [DefaultTexts.FORGOT_YOUR_PASSWORD]: t('medlog.auth.form.forgot-password'),
    [DefaultTexts.SEND_CODE]: t('medlog.auth.form.reset-password.send-code'),
    [DefaultTexts.BACK_SIGN_IN]: t('medlog.auth.form.back-sign-in'),
    [DefaultTexts.SENDING]: t('medlog.auth.form.sending'),
    'New Password': t('medlog.auth.reset-password.new-password'),
    [DefaultTexts.CONFIRM_PASSWORD]: t('medlog.auth.reset-password.confirm-password'),
    [DefaultTexts.CODE]: t('medlog.auth.reset-password.code'),
    [DefaultTexts.SUBMIT]: t('medlog.auth.submit'),
    [DefaultTexts.SUBMITTING]: t('medlog.auth.submitting'),
    [DefaultTexts.RESEND_CODE]: t('medlog.auth.reset-password.resend-code'),

    [DefaultTexts.WE_EMAILED]: t('medlog.auth.signUp.we-emailed'),
    [DefaultTexts.CODE_EMAILED]: t('medlog.auth.signUp.we-sent-you'),
    [DefaultTexts.CODE_ARRIVAL]: t('medlog.auth.signUp.we-sent-you-2'),
    [DefaultTexts.ENTER_CODE]: t('medlog.auth.reset-password.code'),
    [DefaultTexts.CONFIRM]: t('medlog.auth.confirm'),
    [DefaultTexts.CONFIRMING]: t('medlog.auth.confirming'),
  })

  I18n.setLanguage('de')

  const validateCustomSignUp = async (formData: Record<string, unknown>) => {
    if (!formData.acknowledgement) {
      return {
        acknowledgement: 'You must agree to the Terms & Conditions',
      }
    }
  }

  return (
    <S.AuthProvider>
      <Head>
        <title>MedLog | Patient 21</title>
        <meta name="description" content="MedLog | Patient 21" />
      </Head>

      <Authenticator loginMechanisms={['email']} components={components} services={{ validateCustomSignUp }}>
        {children}
      </Authenticator>
    </S.AuthProvider>
  )
}
