import { styled } from '@mui/material/styles'

export const Container = styled('main')`
  display: flex;
  gap: 24px;
  height: 100%;
`

export const ListContainer = styled('div')`
  width: 320px;
`

export const MainAreaContainer = styled('div')`
  flex: 1;
  margin-bottom: 30px;
`
