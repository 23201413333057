import { colors } from '@dentalux/ui-library-core'

import { Button, Stack, styled } from '@mui/material'

import { medlogColors } from '~/providers/ThemeProvider'

export const TopBar = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
`

export const Actions = styled('div')`
  display: flex;
  align-items: center;
  gap: 24px;
`

export const ContactUsButton = styled(Button)`
  color: ${colors.aqua[800]} !important;
`

export const ContactUsContainer = styled(Stack)`
  width: 300px;
  padding: 16px;
  padding-right: 8px;
  background: ${medlogColors.green[500]};
`
