import { Auth } from 'aws-amplify'
import axios, { AxiosRequestConfig } from 'axios'

import { addErrorInterceptors } from './interceptors/errors'

export const http = axios.create({
  baseURL: process.env.NEXT_PUBLIC_ENDPOINT ?? '',
  withCredentials: true,
})

const requestInterceptor = async (config: AxiosRequestConfig): Promise<AxiosRequestConfig> => {
  const token = await getJwtToken()
  if (config.headers) {
    config.headers.authorization = `Bearer ${token}`
  }
  return config
}

export const getJwtToken = async () => {
  const auth = await Auth.currentSession()
  return auth.getIdToken().getJwtToken()
}

addErrorInterceptors(http)
http.interceptors.request.use(requestInterceptor)
