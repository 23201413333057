import { colors } from '@dentalux/ui-library-core'

import { Button, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'

export const NewRecordingButton = styled(Button)`
    width: 100%;
    height: 48px;
    background: ${colors.grey[50]};
    color: ${colors.aqua[800]};
    font-size: 20px;
    border: 1px dotted ${colors.aqua[800]};
    border-radius: 8px;
    font-weight: 500;

    &:hover {
        background: ${colors.aqua[75]};
        color: ${colors.aqua[900]};
        cursor: pointer;
        border: none;
        box-shadow: none;
    }

    & .MuiSvgIcon-root {
        width: 24px;
        height: 24px;
    }
,
`

export const RecordingItemPlaceholder = styled('li')`
  width: 100%;
  height: 90px;
  border: 1px dotted ${colors.grey[500]};
  border-radius: 8px;

  & + li {
    margin-top: 10px;
  }
`

export const AllRecordingsContainer = styled('div')`
  height: 90%;
  overflow: auto;
  margin-top: 8px;
`

export const RecordingItems = styled('ul')`
  margin: 15px 0;

  & li + li {
    margin-top: 14px;
  }
`

export const DateGroupContainer = styled('div')`
  margin-top: 30px;
  margin-bottom: 30px;
`

export const Date = styled(Typography)`
  font-size: 16px;
  letter-spacing: 0.15px;
`
