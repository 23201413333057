import React from 'react'

import { useTranslation } from 'react-i18next'

import { Close, Email } from '@mui/icons-material'
import { Button, IconButton, Popover, Stack, Typography } from '@mui/material'

import * as S from '~/components/TopBar/TopBar.styles'

export const ContactUs = () => {
  const { t } = useTranslation()
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)

  const displayPopover = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)

  return (
    <>
      <S.ContactUsButton variant={'borderless'} startIcon={<Email />} onClick={displayPopover}>
        {t('medlog.contactUs.button')}
      </S.ContactUsButton>

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        slotProps={{
          paper: {
            sx: {
              borderRadius: 2,
            },
          },
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
      >
        <S.ContactUsContainer>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Typography variant={'Lato H5 Bold'}>{t('medlog.contactUs.title')}</Typography>
            <IconButton onClick={handleClose}>
              <Close />
            </IconButton>
          </Stack>

          <Button variant={'borderless'} href={`mailto:${t('medlog.contactUs.link')}`} startIcon={<Email />}>
            {t('medlog.contactUs.link')}
          </Button>
        </S.ContactUsContainer>
      </Popover>
    </>
  )
}
