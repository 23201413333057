import React from 'react'

import { useTranslation } from 'react-i18next'

import { Delete, Edit, MoreVert } from '@mui/icons-material'
import { ListItemIcon, ListItemText, MenuItem, Typography } from '@mui/material'

import * as S from './RecordingMenu.styles'

interface RecordingMenuProps {
  onEditClicked: () => unknown
  onDeleteClicked: () => unknown
  active: boolean
}

export const RecordingMenu = ({ onEditClicked, onDeleteClicked, active }: RecordingMenuProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const { t } = useTranslation()

  const handleDeleteClick = (event: React.MouseEvent<HTMLElement>) => {
    onDeleteClicked()
    handleClose(event)
  }
  const handleEditClick = (event: React.MouseEvent<HTMLElement>) => {
    onEditClicked()
    handleClose(event)
  }

  const handleClose = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault()
    setAnchorEl(null)
  }

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault()
    setAnchorEl(event.currentTarget)
  }

  return (
    <div>
      <S.TriggerButton onClick={handleOpen}>
        <MoreVert />
      </S.TriggerButton>

      <S.ContextMenu anchorEl={anchorEl} open={open} onClose={handleClose} active={active}>
        <MenuItem onClick={handleEditClick}>
          <ListItemIcon>
            <Edit />
          </ListItemIcon>
          <ListItemText>
            <Typography>{t('medlog.recording.menu.edit')}</Typography>
          </ListItemText>
        </MenuItem>
        <MenuItem onClick={handleDeleteClick}>
          <ListItemIcon>
            <Delete />
          </ListItemIcon>
          <ListItemText>
            <Typography>{t('medlog.recording.menu.delete')}</Typography>
          </ListItemText>
        </MenuItem>
      </S.ContextMenu>
    </div>
  )
}
