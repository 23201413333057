import { DateLike } from '~/types/common'

import { format, formatDuration, intervalToDuration, parseISO } from 'date-fns'

export const formatDate = (date: DateLike): string => {
  const dateObject = typeof date === 'string' ? parseISO(date) : date
  return format(dateObject, 'dd.MM.yyyy')
}

export const formatTime = (date: DateLike): string => {
  const dateObject = typeof date === 'string' ? parseISO(date) : date
  return format(dateObject, 'HH:mm')
}

export const formatDateTime = (date: DateLike | undefined): string => {
  if (!date) return ''
  const dateObject = typeof date === 'string' ? parseISO(date) : date
  return format(dateObject, 'dd.MM.yyyy HH:mm')
}

export const formatTimePassed = (seconds: number): string => {
  const duration = intervalToDuration({ start: 0, end: seconds * 1000 })

  return formatDuration(duration, {
    format: ['minutes', 'seconds'],
    zero: true,
    delimiter: ':',
    locale: {
      formatDistance: (_token, count) => zeroPad(count),
    },
  })
}

const zeroPad = (num: number): string => String(num).padStart(2, '0')
