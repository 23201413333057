import { AuthNavigationLayout } from '~/components/AuthNavigationLayout'
import { Recordings } from '~/features/Recordings'
import { NextPageWithLayout } from '~/helpers/types'
import { withTranslation } from '~/helpers/withTranslation'

const RecordingsPage: NextPageWithLayout = () => {
  return <Recordings />
}

RecordingsPage.getLayout = function getLayout(page) {
  return <AuthNavigationLayout>{page}</AuthNavigationLayout>
}

export const getStaticProps = withTranslation()

export default RecordingsPage
