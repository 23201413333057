import React from 'react'

import Link from 'next/link'

import { RecordingMenu } from '~/features/Recordings/components/RecordingMenu/RecordingMenu'
import { formatTime } from '~/helpers/date'
import { useIsCurrentRecording } from '~/hooks/useIsCurrentRecording'
import { Recording } from '~/types/Recordings'

import * as S from './RecordingListItem.styles'

export interface RecordingListItemProps {
  recording: Recording
  onDeleteClicked: (r: Recording) => unknown
  onEditClicked: (e: Recording) => unknown
}

export const RecordingListItem = ({ recording, onDeleteClicked, onEditClicked }: RecordingListItemProps) => {
  const active = useIsCurrentRecording(recording)

  const handleDelete = () => {
    onDeleteClicked(recording)
  }
  const handleEdit = () => {
    onEditClicked(recording)
  }

  return (
    <Link href={`/recordings/${recording.referenceId}`}>
      <S.Container active={active}>
        <S.TitlePatientContainer>
          <S.Title>{recording.title}</S.Title>
          <S.PatientNumber>{recording.patientNumber}</S.PatientNumber>
        </S.TitlePatientContainer>

        <S.MetaContainer>
          <RecordingMenu onDeleteClicked={handleDelete} onEditClicked={handleEdit} active={active} />
          <S.Time> {formatTime(recording.createdAt)}</S.Time>
        </S.MetaContainer>
      </S.Container>
    </Link>
  )
}
