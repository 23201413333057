import * as S from '~/components/NavLogo/NavLogo.styles'
import { APP_NAME } from '~/constants/common'

export const NavLogo = () => {
  return (
    <S.Group>
      <S.Logo />
      <S.AppName variant="Lato H5">{APP_NAME}</S.AppName>
    </S.Group>
  )
}
