import { styled } from '@mui/material'

export const Container = styled('div')`
  height: 100vh;
  overflow: hidden;
  padding: 20px;
`

export const Content = styled('div')`
  overflow: auto;
  height: 100%;
  padding: 20px 0;
`
