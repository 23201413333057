import { GlobalServerErrorTranslations } from '~/types/ServerError'

export const errors = {
  400: 'errors.validation',
  401: 'errors.unauthorized',
  403: 'errors.forbidden',
  404: 'errors.not_found',
  409: 'errors.conflict',
  422: 'errors.validation',
  500: 'errors.sever_error',
  501: 'errors.sever_error',
  502: 'errors.sever_error',
  503: 'errors.sever_error',
  504: 'medlog.summary.timeout_error',
} as GlobalServerErrorTranslations
