import { colors } from '@dentalux/ui-library-core'

import { Typography } from '@mui/material'
import { styled } from '@mui/material/styles'

import { medlogColors } from '~/providers/ThemeProvider'

interface ActiveProps {
  active?: boolean
}

export const Container = styled('a')<ActiveProps>((props) => ({
  background: props.active ? medlogColors.blue[500] : colors.grey[100],
  color: `${colors.grey[800]}`,
  padding: '10px',
  borderRadius: '8px',
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'stretch',
  cursor: 'pointer',

  ['&:hover']: {
    background: props.active ? medlogColors.blue[500] : medlogColors.green[500],
  },
}))

export const Title = styled(Typography)`
  font-size: 16px;
  font-weight: 700;
  word-break: break-word;
`
export const PatientNumber = styled(Typography)`
  font-size: 12px;
  word-break: break-word;
  line-height: initial;
`

export const TitlePatientContainer = styled('div')`
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  align-self: center;
`

export const MetaContainer = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 40px;
`

export const Time = styled(Typography)`
  font-size: 12px;
  line-height: initial;
`
