import { createContext, PropsWithChildren, useContext } from 'react'

import { RecordingWithId } from '~/types/Recordings'

interface CurrentRecordingProviderData {
  recording: RecordingWithId
  refetch: () => Promise<unknown>
}

const Context = createContext<CurrentRecordingProviderData>({} as CurrentRecordingProviderData)

export const CurrentRecordingProvider = ({
  children,
  recording,
  refetch,
}: PropsWithChildren<CurrentRecordingProviderData>) => {
  const data: CurrentRecordingProviderData = {
    recording,
    refetch,
  }
  return <Context.Provider value={data}>{children}</Context.Provider>
}

export const useCurrentRecording = () => {
  const context = useContext(Context)

  if (context === undefined) {
    throw new Error('useRecording must be used within a RecordingProvider')
  }

  return context
}
