import { colors } from '@dentalux/ui-library-core'

import { styled } from '@mui/material/styles'

import { LogoIcon } from '~/icons/LogoIcon'

export const Container = styled('div')`
  border: 2px solid ${colors.grey['100']};
  border-radius: 24px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 18px;
`

export const Logo = styled(LogoIcon)`
  fill: ${colors.aqua[100]};
  width: 200px;
  height: 90px;
`
