import { QueryObserverResult, useQuery } from 'react-query'

import { useRouter } from 'next/router'

import { formatDate } from '~/helpers/date'
import { getNotes } from '~/services/medlog/notes'
import { UUID } from '~/types/common'
import { Recording, RecordingsPerDay, RecordingWithId } from '~/types/Recordings'

type UseRecordingsReturn = {
  recordingsPerDay: RecordingsPerDay
  recordingsQuery: Omit<QueryObserverResult<Array<Recording>>, 'data'>
  currentRecording?: RecordingWithId
  hasAnyRecording: boolean | undefined
  firstDefaultRecordingId?: UUID
}

export const useRecordings = (): UseRecordingsReturn => {
  const { data, ...recordingsQuery } = useQuery(['recordings'], getNotes)

  const router = useRouter()
  const { id } = router.query

  const currentRecording = data?.find((r) => r.referenceId === id) as RecordingWithId

  const recordingsPerDay =
    data?.reduce((acc, recording: Recording) => {
      const day = formatDate(recording.createdAt)
      const currentDay = acc[day] ?? []
      return { ...acc, [day]: [...currentDay, recording] }
    }, {} as RecordingsPerDay) ?? {}

  const firstDefaultRecordingId = currentRecording?.referenceId ?? data?.[0]?.referenceId

  return {
    recordingsPerDay,
    currentRecording,
    recordingsQuery,
    hasAnyRecording: data?.length === undefined ? undefined : data.length > 0,
    firstDefaultRecordingId,
  }
}
