import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'

import { createEmptyNote } from '~/services/medlog/notes'
import { Recording } from '~/types/Recordings'

export const emptyRecording = (title: string, patientNumber: string): Recording => ({
  createdAt: new Date(),
  title,
  patientNumber,
  transcriptions: [],
  summaries: [],
})

export const useCreateEmptyRecording = () => {
  const { t } = useTranslation()

  const mutation = useMutation(() =>
    createEmptyNote(emptyRecording(t('medlog.recording.new.title'), t('medlog.recording.new.patient')))
  )

  return mutation
}
