import React, { useEffect } from 'react'

import { useTranslation } from 'react-i18next'

import Head from 'next/head'

import { Person } from '@mui/icons-material'
import { Button } from '@mui/material'

import { NavLogo } from '~/components/NavLogo'
import { SubscriptionButton } from '~/components/SubscriptionButton'
import { ContactUs } from '~/components/TopBar/ContactUs'
import { useCookieBanner } from '~/hooks/useCookieBanner'

import { useAuthenticator } from '@aws-amplify/ui-react'
import posthogClient from 'posthog-js'

import * as S from './TopBar.styles'

export const TopBar = () => {
  const { t } = useTranslation()

  const auth = useAuthenticator()
  const { hideCookieButton } = useCookieBanner()
  const userLoggedIn = Boolean(auth.user)

  const handleLogout = () => {
    auth.user?.signOut(() => {
      window.location.href = '/'
    })
  }

  useEffect(() => {
    if (userLoggedIn) {
      hideCookieButton()
    }
  }, [userLoggedIn, hideCookieButton])

  useEffect(() => {
    posthogClient.identify(auth.user.username, {}, { email: auth.user.attributes?.email })
  }, [auth.user.username, auth.user.attributes?.email])

  return (
    <>
      <Head>
        <title>MedLog | Patient 21</title>
        <meta name="description" content="MedLog | Patient 21" />
      </Head>

      <S.TopBar>
        <NavLogo />
        <S.Actions>
          <SubscriptionButton fullWidth={false}>{t('medlog.top_bar.subscription')}</SubscriptionButton>
          <Button variant={'bordered'} startIcon={<Person />} onClick={handleLogout}>
            {t('medlog.top_bar.logout')}
          </Button>
          <ContactUs />
        </S.Actions>
      </S.TopBar>
    </>
  )
}
