import { addSnackbarMessage } from '~/providers/SnackbarProvider'

import * as Sentry from '@sentry/nextjs'
import { AxiosInstance } from 'axios'
import truncate from 'lodash.truncate'

import { errors as globalTranslations } from '../../../i18n/common/errors'

const MESSAGE_CHARS_LIMIT = 500
const BLACKLIST_URLS = ['authenticate']
const BLACKLIST_STATUSES: Array<number> = []

export const addErrorInterceptors = (http: AxiosInstance) =>
  http.interceptors.response.use(
    (response) => response,
    async (error) => {
      const isTimeout = !error?.response && error?.config?.url?.includes('summary')
      const status = isTimeout ? 504 : error?.response?.status ?? 500

      if (
        // Prevent showing snackbars for specific urls
        BLACKLIST_URLS.some((url) => error?.config?.url?.includes(url)) ||
        // Prevent showing snackbars for some statuses, beacuse they are handled separately
        BLACKLIST_STATUSES.some((blacklistedStatus) => blacklistedStatus === status) ||
        // Cancelled requests handling
        error?.message === 'canceled' ||
        // Responses with error code are handled manually
        error?.response?.data?.code
      ) {
        return Promise.reject(error)
      }

      const traceId = error?.response?.headers?.['x-b3-traceid']
      const isPlainText = error?.response?.headers?.['content-type']?.includes('plain')
      const isHtml = error?.response?.headers?.['content-type']?.includes('html')
      let sentryId

      // Don't show error details if it's an HTML response
      let responseError = isHtml ? '' : error?.response?.data

      // Handle multiple keys in error object responses.
      const errorResponseKeys = ['message', 'error']

      const message = globalTranslations[status]

      if (!isPlainText && !isHtml && responseError) {
        const errorKeyToUse = Object.keys(responseError).find((key) => errorResponseKeys.includes(key))

        if (errorKeyToUse) {
          responseError = responseError[errorKeyToUse]
        }
      }

      const details = isTimeout ? undefined : `${truncate(responseError, { length: MESSAGE_CHARS_LIMIT }) ?? ''}`
      const fields = error?.response?.data?.fields

      if (process.env.NODE_ENV !== 'development') {
        Sentry.withScope((scope) => {
          const user = {
            ...scope.getUser(),
            ...(traceId && {
              traceId,
            }),
          }

          const context = traceId && {
            tags: {
              traceId,
            },
          }

          scope.setUser(user)

          const message = isTimeout
            ? `The server timed out before returning the response: ${error?.config?.url}`
            : `${status}, ${responseError}`
          sentryId = Sentry.captureException(new Error(message), context)
        })
      }

      addSnackbarMessage({
        variant: isTimeout ? 'info' : 'error',
        message,
        details,
        status,
        fields,
        sentryId,
        autoHideDuration: isTimeout ? 20_000 : undefined,
      })
      return Promise.reject(error)
    }
  )
