import { useMutation } from 'react-query'

import { ButtonProps } from '@mui/material'

import { LoadingButton } from '~/components/LoadingButton'
import { goToSubscription } from '~/services/medlog/payments'

type SubscriptionButtonProps = ButtonProps

export const SubscriptionButton = (props: SubscriptionButtonProps) => {
  const { mutateAsync: generateSubscriptionLink, isLoading } = useMutation(() => goToSubscription())

  const handleClick = async () => {
    const { redirectUrl } = await generateSubscriptionLink()
    window.location.href = redirectUrl
  }

  return <LoadingButton loading={isLoading} fullWidth onClick={handleClick} {...props} />
}
