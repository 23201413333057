import { FormEvent } from 'react'

import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'

import { Button, Dialog, DialogActions, DialogTitle, Divider, TextField } from '@mui/material'

import { editNote } from '~/services/medlog/notes'
import { Recording } from '~/types/Recordings'

import { create, muiDialogV5, useModal } from '@ebay/nice-modal-react'

import * as S from './EditDataDialog.styles'

interface EditDataDialogProps {
  recording: Recording
}

export const EditDataDialog = create(({ recording }: EditDataDialogProps) => {
  const modal = useModal()
  const { t } = useTranslation()

  const { mutateAsync: doEdit, isLoading } = useMutation(editNote)

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const formData = new FormData(e.currentTarget)
    const updated = Object.fromEntries(formData) as unknown as Recording
    await doEdit({ ...recording, ...updated })

    await modal.resolve(undefined)
    await handleClose()
  }

  const handleClose = async () => {
    await modal.hide()
  }

  return (
    <Dialog {...muiDialogV5(modal)} fullWidth maxWidth={'xs'}>
      <DialogTitle>{t('medlog.recording.edit-dialog.title')}</DialogTitle>
      <Divider />
      <form onSubmit={handleSubmit}>
        <S.FormContent>
          <TextField
            autoFocus
            label={t('medlog.recording.edit-dialog.title-label')}
            defaultValue={recording.title}
            name="title"
            variant="filled"
          />
          <TextField
            label={t('medlog.recording.edit-dialog.patient-label')}
            defaultValue={recording.patientNumber}
            name="patientNumber"
            variant="filled"
          />
        </S.FormContent>

        <DialogActions>
          <Button variant="borderless" onClick={handleClose} disabled={isLoading}>
            {t('medlog.recording.dialog.cancel')}
          </Button>
          <Button variant="borderless" type="submit" disabled={isLoading}>
            {t('medlog.recording.dialog.confirm')}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
})
