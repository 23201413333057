import { colors } from '@dentalux/ui-library-core'

import { Button, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'

import { LogoIcon } from '~/icons/LogoIcon'
import { medlogColors } from '~/providers/ThemeProvider'

import { View } from '@aws-amplify/ui-react'

export const AuthProvider = styled('div')`
  & a {
    color: inherit;

    &:visited {
      color: inherit;
    }
  }

  [data-amplify-authenticator] {
    --amplify-fonts-default-variable: Lato;
    font-family: var(--amplify-fonts-default-variable), sans-serif;
    --amplify-components-authenticator-router-background-color: ${medlogColors.green[500]};
    --amplify-components-authenticator-state-inactive-background-color: ${medlogColors.green[500]};
    --amplify-components-authenticator-router-border-width: 0;
    --amplify-components-authenticator-router-box-shadow: 0;
    --amplify-components-authenticator-container-width-max: 560px;
    --amplify-components-fieldcontrol-padding-block-start: 16px;
    --amplify-components-fieldcontrol-padding-block-end: 16px;
    --amplify-components-fieldcontrol-padding-inline-start: 16px;
    --amplify-components-fieldcontrol-padding-inline-end: 12px;
    --amplify-components-fieldcontrol-font-size: 14px;
    --amplify-components-flex-gap: 20px;
    --amplify-components-button-primary-background-color: ${colors.grey[0]};
    --amplify-components-button-primary-color: ${colors.aqua[500]};
    --amplify-components-fieldcontrol-border-width: 0;
    --amplify-components-fieldcontrol-border-radius: 6px;
    --amplify-components-button-primary-hover-background-color: ${colors.aqua[500]};
    --amplify-components-button-primary-hover-color: ${colors.grey[0]};
    --amplify-components-passwordfield-button-hover-background-color: transparent;
    --amplify-components-passwordfield-button-active-background-color: transparent;

    height: 100vh;

    & > div {
      border: 1px solid ${colors.aqua[100]};
      border-radius: 16px;
    }

    [data-amplify-router] {
      border-bottom-left-radius: 16px;
      border-bottom-right-radius: 16px;
      flex: 1;
    }

    & [data-amplify-container] {
      min-height: 650px;
      display: flex;
      flex-direction: column;
      box-shadow: 0 1px 1px 0 rgba(33, 33, 33, 0.06), 0 0 10px 0 rgba(33, 33, 33, 0.1);
    }

    & [data-amplify-form] {
      padding-right: 100px;
      padding-left: 100px;
    }

    & .amplify-tabs {
      margin: 0 auto;
      width: 340px;
      background: ${colors.aqua[800]};
      border-radius: 8px;
      padding-top: 2px;
      border: none;
    }

    & .amplify-tabs-item {
      background-color: ${colors.aqua[800]};
      color: ${colors.grey['0']};
      border: none;
      border-radius: 6px;
    }

    & .amplify-tabs-item[data-state='active'] {
      background-color: white;
      color: ${colors.aqua[800]};
      border: 2px solid ${colors.aqua[800]};
    }

    & .amplify-field-group {
      background: ${colors.grey[0]};
      border-radius: 6px;
    }

    & .amplify-field__show-password {
      border: none;
    }

    & .amplify-label {
      display: none;
    }

    & button.amplify-field-group__control.amplify-button--primary {
      margin-top: 40px;
      border-radius: 100px;
      font-size: 14px;
      height: 48px;

      :not([disabled]) {
        border: 1px solid ${colors.aqua[500]};
      }
    }

    [data-amplify-authenticator-confirmsignup] .amplify-button {
      --amplify-internal-button-border-width: 0;
      --amplify-internal-button-color: ${colors.aqua[500]};
      --amplify-components-button-hover-background-color: transparent;
      --amplify-components-button-font-size: 13px;
    }

    .amplify-button {
      --amplify-font-weights-normal: 700;
    }

    & .amplify-button--link {
      --amplify-components-button-small-font-size: 13px;
      --amplify-components-button-link-hover-background-color: transparent;
      --amplify-components-button-link-color: ${colors.aqua[500]};
      --amplify-font-weights-normal: 500;
    }

    [data-amplify-authenticator-resetpassword] .amplify-button--link {
      display: none;
    }
  }
`

export const Prompt = styled(Typography)`
  color: ${colors.aqua[700]};
  padding-left: 100px;
  padding-right: 100px;
  margin-left: 6px;
  margin-top: 20px;
`

export const Acknowledgement = styled(Typography)`
  color: ${colors.aqua[700]};
`

export const LogoHeader = styled(View)`
  background: ${medlogColors.green[500]};
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  padding-top: 60px;
  padding-bottom: 24px;
  position: relative;
`

export const BackButton = styled(Button)`
  position: absolute;
  top: 60px;
  left: 40px;
`

export const MedlogLogo = styled(LogoIcon)`
  fill: ${colors.aqua[700]};
  width: 56px;
  height: 24px;
`

export const AppName = styled(Typography)`
  margin-top: -6px;
  color: ${colors.aqua[700]};
`
