import React, { PropsWithChildren } from 'react'

import { TopBar } from '~/components/TopBar'
import { AwsCognitoAuthenticator } from '~/features/Auth/AwsCognitoAuthenticator'

import * as S from './AuthNavigationLayout.styles'

export const AuthNavigationLayout = ({ children }: PropsWithChildren) => {
  return (
    <AwsCognitoAuthenticator>
      <S.Container>
        <TopBar />
        <S.Content>{children}</S.Content>
      </S.Container>
    </AwsCognitoAuthenticator>
  )
}
