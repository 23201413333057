import { useTranslation } from 'react-i18next'

import { colors } from '@dentalux/ui-library-core'

import { ArrowBackIos } from '@mui/icons-material'
import { Checkbox, FormControlLabel, Typography } from '@mui/material'

import * as S from '~/features/Auth/Auth.styles'

import { AuthenticatorRoute } from '@aws-amplify/ui'
import { Authenticator, useAuthenticator } from '@aws-amplify/ui-react'

export const components = {
  ConfirmResetPassword: {
    Header() {
      const { t } = useTranslation()

      return <Typography color={colors.aqua[700]}>{t('medlog.auth.form.reset-password.confirm-label')}</Typography>
    },
  },

  ResetPassword: {
    Header() {
      const { t } = useTranslation()

      return <Typography color={colors.aqua[700]}>{t('medlog.auth.form.reset-password.label')}</Typography>
    },
  },

  SignUp: {
    FormFields() {
      const { t } = useTranslation()

      return (
        <>
          <Authenticator.SignUp.FormFields />

          <FormControlLabel
            name="acknowledgement"
            control={<Checkbox name="acknowledgement" />}
            label={<S.Acknowledgement dangerouslySetInnerHTML={{ __html: t('medlog.auth.signUp.terms.text') }} />}
          />
        </>
      )
    },
  },

  Header() {
    const { route, toSignIn } = useAuthenticator()
    const { t } = useTranslation()

    const backButtonActions = new Map<AuthenticatorRoute, () => void>([
      ['resetPassword', toSignIn],
      ['confirmResetPassword', toSignIn],
      ['confirmSignIn', toSignIn],
      ['confirmSignUp', toSignIn],
      ['confirmVerifyUser', toSignIn],
    ])

    const backButtonAction = backButtonActions.get(route)

    return (
      <S.LogoHeader textAlign="center">
        {backButtonAction && (
          <S.BackButton variant={'borderless'} startIcon={<ArrowBackIos />} onClick={backButtonAction}>
            {t('medlog.auth.back')}
          </S.BackButton>
        )}
        <S.MedlogLogo />
        <S.AppName variant="Lato Body 1">Medlog</S.AppName>

        <Typography variant="Lato H4" color={colors.aqua[700]} mt={3}>
          {t(`medlog.auth.${route}.title`)}
        </Typography>
      </S.LogoHeader>
    )
  },
}
