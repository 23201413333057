import { useRouter } from 'next/router'

import { Recording } from '~/types/Recordings'

export const useIsCurrentRecording = (recording: Recording) => {
  const router = useRouter()
  const { id } = router.query

  return recording.referenceId === id
}
