import { useRef } from 'react'

import { useTranslation } from 'react-i18next'

import { useRouter } from 'next/router'

import { Add } from '@mui/icons-material'

import { ConfirmDeleteDialog } from '~/features/Recordings/components/ConfirmDeleteDialog/ConfirmDeleteDialog'
import { EditDataDialog } from '~/features/Recordings/components/EditDataDialog/EditDataDialog'
import { RecordingListItem } from '~/features/Recordings/components/RecordingListItem/RecordingListItem'
import { useCreateEmptyRecording } from '~/hooks/useCreateEmptyRecording'
import { useRecordings } from '~/hooks/useRecordings'
import { Recording } from '~/types/Recordings'

import { show as showDialog } from '@ebay/nice-modal-react'

import * as S from './RecordingList.styles'

export const RecordingList = () => {
  const { t } = useTranslation()
  const listRef = useRef<HTMLDivElement>(null)

  const { recordingsPerDay, recordingsQuery, hasAnyRecording } = useRecordings()
  const { mutateAsync: createEmptyRecording } = useCreateEmptyRecording()

  const router = useRouter()

  const handleNewRecording = async () => {
    const res = await createEmptyRecording()
    await recordingsQuery.refetch()
    await router.push(`/recordings/${res.referenceId}`)
    listRef.current?.scrollTo({ top: 0, behavior: 'smooth' })
  }

  const handleDeleteRecordingClicked = async (recording: Recording) => {
    await showDialog(ConfirmDeleteDialog, { recording })
    await recordingsQuery.refetch()
  }

  const handleEditRecordingClicked = async (recording: Recording) => {
    await showDialog(EditDataDialog, { recording })
    await recordingsQuery.refetch()
  }

  return (
    <>
      <S.NewRecordingButton startIcon={<Add />} onClick={handleNewRecording}>
        {t('medlog.recordings.new')}
      </S.NewRecordingButton>

      {!hasAnyRecording && (
        <S.RecordingItems>
          <S.RecordingItemPlaceholder />
          <S.RecordingItemPlaceholder />
          <S.RecordingItemPlaceholder />
        </S.RecordingItems>
      )}

      {hasAnyRecording && (
        <S.AllRecordingsContainer ref={listRef}>
          {Object.entries(recordingsPerDay).map(([date, recordings]) => (
            <S.DateGroupContainer key={date}>
              <S.Date>{date}</S.Date>
              <S.RecordingItems>
                {recordings.map((recording) => (
                  <li key={recording.referenceId}>
                    <RecordingListItem
                      recording={recording}
                      onDeleteClicked={handleDeleteRecordingClicked}
                      onEditClicked={handleEditRecordingClicked}
                    />
                  </li>
                ))}
              </S.RecordingItems>
            </S.DateGroupContainer>
          ))}
        </S.AllRecordingsContainer>
      )}
    </>
  )
}
