import { styled } from '@mui/material/styles'

export const TitleContainer = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
`

export const DescriptionContainer = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 24px;
  text-align: center;
`

export const RecordingData = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 4px;
`
