import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'

import { Delete } from '@mui/icons-material'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material'

import { formatDate, formatTime } from '~/helpers/date'
import { deleteNote } from '~/services/medlog/notes'
import { Recording } from '~/types/Recordings'

import { muiDialogV5, useModal, create as createDialog } from '@ebay/nice-modal-react'

import * as S from './ConfirmDeleteDialog.styles'

interface ConfirmDeleteDialogProps {
  recording: Recording
}

export const ConfirmDeleteDialog = createDialog(({ recording }: ConfirmDeleteDialogProps) => {
  const { t } = useTranslation()

  const modal = useModal()

  const { mutateAsync: doDeleteNote, isLoading } = useMutation(deleteNote)

  const handleClose = async () => {
    await modal.hide()
  }

  const handleDelete = async () => {
    if (recording.referenceId) await doDeleteNote(recording.referenceId)
    await modal.resolve(undefined)
    await handleClose()
  }

  return (
    <Dialog {...muiDialogV5(modal)} fullWidth maxWidth={'xs'}>
      <DialogTitle sx={{ justifyContent: 'center' }}>
        <S.TitleContainer>
          <Delete />
          {t('medlog.recording.delete-dialog.title')}
        </S.TitleContainer>
      </DialogTitle>
      <DialogContent>
        <S.DescriptionContainer>
          <S.RecordingData>
            <Typography fontWeight={700} fontSize={20}>
              {recording.title}
            </Typography>
            <Typography fontSize={18}>
              {formatDate(recording.createdAt)}, {formatTime(recording.createdAt)}
            </Typography>
          </S.RecordingData>
          <Typography fontSize={16}>{t('medlog.recording.delete-dialog.description')}</Typography>
        </S.DescriptionContainer>
      </DialogContent>
      <DialogActions>
        <Button variant="borderless" onClick={handleClose} disabled={isLoading}>
          {t('medlog.recording.dialog.cancel')}
        </Button>
        <Button variant="borderless" onClick={handleDelete} disabled={isLoading}>
          {t('medlog.recording.delete-dialog.confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  )
})
