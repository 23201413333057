import { useEffect } from 'react'

import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'

import { RecordingList } from '~/features/Recordings/components/RecordingList/RecordingList'
import { TranscriptionSummaryProps } from '~/features/Recordings/components/TranscriptionSummary/TranscriptionSummary'
import { CurrentRecordingProvider } from '~/features/Recordings/providers/CurrentRecordingProvider'
import { WelcomeScreen } from '~/features/WelcomeScreen'
import { useRecordings } from '~/hooks/useRecordings'

import * as S from './Recordings.styles'

const TranscriptionSummaryClientSide = dynamic<TranscriptionSummaryProps>(
  () =>
    import('~/features/Recordings/components/TranscriptionSummary/TranscriptionSummary').then(
      (module) => module.TranscriptionSummary
    ),
  {
    ssr: false,
  }
)

export const Recordings = () => {
  const router = useRouter()
  const { firstDefaultRecordingId, hasAnyRecording, currentRecording, recordingsQuery } = useRecordings()

  useEffect(() => {
    if (firstDefaultRecordingId) {
      router.push(`/recordings/${firstDefaultRecordingId}`)
    }
    // we cannot put 'router' here
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstDefaultRecordingId])

  return (
    <S.Container>
      <S.ListContainer>
        <RecordingList />
      </S.ListContainer>
      <S.MainAreaContainer>
        {hasAnyRecording === false && <WelcomeScreen />}

        {currentRecording && (
          <CurrentRecordingProvider recording={currentRecording} refetch={recordingsQuery.refetch}>
            <TranscriptionSummaryClientSide key={currentRecording.referenceId} />
          </CurrentRecordingProvider>
        )}
      </S.MainAreaContainer>
    </S.Container>
  )
}
