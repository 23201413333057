import { http } from '~/services/axios'
import { Recording } from '~/types/Recordings'

export const getNotes = async (): Promise<Recording[]> => {
  const { data } = await http.get('/api/v1/recordings')
  return data
}

export const createEmptyNote = async (recording: Recording): Promise<Recording> => {
  const { data } = await http.post('/api/v1/recordings', recording)
  return data
}

export const deleteNote = async (id: string): Promise<void> => {
  await http.delete(`/api/v1/recordings/${id}`)
}

export const editNote = async (recording: Recording): Promise<void> => {
  await http.put(`/api/v1/recordings/${recording.referenceId}`, recording)
}
